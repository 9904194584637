import React, { useState, useEffect } from "react"
import styles from "./success.module.scss"
import Layout from "../Layout"
import Button from "../Button"
import SvgIcon from "../SvgIcon"
import BudText from "../TextBudStage/"
import CardVideo from "../CardVideo"
import { IsMobileDevice } from "../../../utils"
// import { navigate } from 'gatsby';

const Success = () => {
  const [isMobile, setIsMobile] = useState()
  const [data, setdata] = useState({})

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    const coverData = JSON.parse(localStorage.getItem("coverData")) || {}
    // Se agrega el video id para que se renderice la card del video
    coverData.videoId = "success"
    setdata(coverData)
    return () => {
      localStorage.removeItem("coverData")
    }
  }, [])

  return (
    <div className={styles.success}>
      <Layout noFooter type="secondary">
        <div className={styles.container}>
          <SvgIcon
            className={styles.canIcon}
            size={isMobile ? "20.810vw" : "5.636vw"}
            name="can-icon"
          />
          <SvgIcon
            className={styles.lightningIcon}
            size={isMobile ? "14.174vw" : "3.472vw"}
            name="lightning"
          />
          <SvgIcon
            className={styles.discIcon}
            size={isMobile ? "45.151vw" : "10.417vw"}
            name="disc-icon"
          />
          <SvgIcon
            className={styles.brewIcon}
            size={isMobile ? "18.931vw" : "4.290vw"}
            name="we-brew-icon"
          />
          {isMobile && (
            <SvgIcon
              className={styles.discIcon2}
              size={isMobile ? "23.682vw" : "10.417vw"}
              name="disc-icon"
            />
          )}

          {!isMobile && (
            <>
              <BudText orientation="vertical" className={styles.budText} />
              <SvgIcon
                className={styles.peaceIcon}
                size="2.695vw"
                name="peace-icon"
              />
              <SvgIcon
                className={styles.starIcon}
                size="7.281vw"
                name="star-icon"
              />
              <SvgIcon className={styles.textura} name="textura-fondo" />
            </>
          )}
          {!isMobile && (
            <div className={styles.videoContainer}>
              <CardVideo data={data} toVote />
            </div>
          )}
          <div className={styles.text}>
            <p>¡GRACIAS!</p>
            <p>TU VIDEO FUE ENVIADO.</p>
            <p>
              En las próximas 48hs estaremos validando que tu video cumpla con
              los requisitos necesarios. <br />
              De ser aprobado, te enviaremos un mail con un link para que lo
              compartas el tema en tus redes y tengas más chances de ganar.
            </p>
            <Button
              label="VOLVER A BUD STAGE"
              onClick={() => window.location.replace(`/budstage/temas`)}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Success
